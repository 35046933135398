<template>
  <b-modal
    id="reservation-payment-modal"
    :visible="showPaymentModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    hide-header
    @hide="resetForm"
    @hidden="resetForm"
    @change="(val) => $emit('update:show-payment-modal', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            Event Registration Payment
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="update_modal_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onMakePaymentNow)"
            @reset.prevent="resetForm"
          >

            <b-alert
              v-if="errorMessage"
              variant="danger"
              :show="true"
            >
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  {{ errorMessage }}
                </b-card-text>
              </div>
            </b-alert>
            
            <p v-if="!reservation.accommodation.payment_installment" class="text-danger">Kindly make full payment to activate your reservation</p>

            <validation-provider
              #default="validationContext"
              name="Amount"
              rules="required"
            >
              <b-form-group
                label="Enter Amount To Pay"
                label-for="amount"
              >
                <b-form-input
                  id="amount"
                  v-model="new_data.amount"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Enter amount to pay"
                  type="number"
                  :readonly="!reservation.accommodation.payment_installment"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Proceed to checkout
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormTextarea, BFormInvalidFeedback, BButton,
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BAlert, BCardText, BModal, BFormCheckbox, BFormTimepicker
} from 'bootstrap-vue'

import { required } from '@validations'
import { get, cloneDeep } from 'lodash';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const rawEventsData = {
  amount: 0,
}

export default {
  components: {
    BForm,
    BAlert,
    BModal,
    BButton,
    vSelect,
    BOverlay,
    BSidebar,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BFormTimepicker,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'showPaymentModal',
    event: 'update:show-payment-modal',
  },
  props: {
    showPaymentModal: {
      type: Boolean,
      required: true,
    },
    reservation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      required,

      loading: false,
      errorMessage: "",
      new_data: cloneDeep(rawEventsData),
    }
  },
  computed: {
    amountToPay() {
      return this.getValueFromSource(this.reservation, 'price') - this.getValueFromSource(this.reservation, 'amount_paid')
    },
  },
  watch: {
    amountToPay: {
      handler(value) {
        console.log('value', value)
        this.new_data.amount = value
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async onMakePaymentNow(){
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.update_modal_form.validate();
        if (!success){
          return;
        }

        const result = await this.$swal({
          title: "Confirm Payment",
          text: "You'll be redirected to checkout screen",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.isConfirmed) {
          return;
        }

        const response = await this.useJwt().clientService.makeReservationPayment(this.reservation._id, this.new_data);
        const { data } = response.data;

        if (data.authorization_url){
          location.href = data.authorization_url;
          this.$emit('update:show-payment-modal', false);
        } else {
          this.$emit('update:show-payment-modal', false);
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    resetForm() {
      this.$emit("hide-modal")
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#reservation-payment-modal {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
